
import Error from "@/components/Error.vue";
import PrettyBar from "@/components/PrettyBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import VDButton from "@/components/VDButton.vue";
import useError from "@/composables/useError";
import useToastService from "@/composables/useToastService";
import {
  RoleType,
  ProfileType,
  useGetProfileApplicationQuery,
} from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import gql from "graphql-tag";
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRef,
  watch,
  inject,
} from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import useConfirmService from "@/composables/useConfirmService";
import { VDPopupListboxClass } from "@/components/pickers/VDPopupListbox";
import RolePicker from "@/views/User/components/RolePicker.vue";

//Mutations
import useCreateProfile from "./mutations/useCreateProfile";
import useUpdateProfile from "./mutations/useUpdateProfile";
import useDeleteProfile from "./mutations/useDeleteProfile";
import useAddRoleProfile from "./mutations/useAddRoleProfile";
import useRemoveRoleProfile from "./mutations/useRemoveRoleProfile";

gql`
  query getProfileApplication($id: ID) {
    profile(profileId: $id) {
      ...ProfileParts
      application {
        applicationId
        name
        roles {
          roleId
          name
        }
      }
      roles {
        roleId
        name
      }
    }
  }
`;

export default defineComponent({
  components: {
    Sidebar,
    PrettyBar,
    Error,
    VDButton,
    RolePicker,
  },
  name: "Profile",
  props: {
    applicationId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const $translate: any = inject("$translate"); //i18n

    // Services
    const toastService = useToastService();
    const router = useRouter();
    const confirmService = useConfirmService("sidebar");

    // VDOM Refs
    const rolePicker = ref<VDPopupListboxClass>();

    const originalProfile = ref<ProfileType>({});
    const profile = reactive<ProfileType>({});
    const isNew = computed(() => props.id == "new");
    const hasConfirmedLeave = ref(false);

    const v$ = useVuelidate(useValidations(), {
      name: toRef(profile, "name"),
    });
    const hasChanges = computed(
      () =>
        !(
          originalProfile.value.name == profile.name &&
          originalProfile.value.description == profile.description
        )
    );

    //Call mutations
    const { result, loading, error } = useGetProfileApplicationQuery(
      props,
      () => ({
        enabled: props.id != "new",
        fetchPolicy: "network-only",
      })
    );

    watch(
      () => useResult(result).value,
      (newValue) => {
        originalProfile.value = newValue ?? {};
        Object.assign(profile, newValue);
      },
      { immediate: true }
    );

    const createProfileMutation = useCreateProfile();
    const updateProfileMutation = useUpdateProfile();
    const deleteProfileMutation = useDeleteProfile();
    const addRoleProfileMutation = useAddRoleProfile();
    const removeRoleProfileMutation = useRemoveRoleProfile();

    // Functions
    function onPrimaryButton() {
      v$.value.$touch();
      if (!v$.value.$error) {
        isNew.value
          ? createProfileMutation.call(props.applicationId, profile)
          : updateProfileMutation.call(props.applicationId, profile);
      }
    }

    function onAddedRole(role: RoleType) {
      function add() {
        profile.roles = [...(profile.roles ?? []), role];
      }

      if (!isNew.value) {
        if (profile.profileId != undefined && role.roleId != undefined) {
          addRoleProfileMutation
            .call(profile.profileId, role.roleId)
            .then((value) => {
              if (value != null) add();
            });
        }
      } else {
        add();
      }
    }

    function onRemovedRole(role: RoleType) {
      function remove() {
        profile.roles =
          profile.roles?.filter((element) => element?.roleId != role.roleId) ??
          [];
      }

      if (!isNew.value) {
        if (profile.profileId != undefined && role.roleId != undefined) {
          removeRoleProfileMutation
            .call(profile.profileId, role.roleId)
            .then((value) => {
              if (value != null) remove();
            });
        }
      } else {
        remove();
      }
    }

    createProfileMutation.onDone(({ data }) => {
      toastService.show({
        title: $translate("TextResources_CreatedProfile"),
        message: `${$translate("TextResources_CreatedProfile")}: ${
          data?.createProfile?.profileId
        }`,
        severity: "success",
      });
      hasConfirmedLeave.value = true;
      router.back();
    });

    updateProfileMutation.onDone(() => {
      toastService.show({
        title: $translate("TextResources_UpdatedProfile"),
        message: $translate("TextResources_UpdatedProfileDescription"),
        severity: "success",
      });
      hasConfirmedLeave.value = true;
      router.back();
    });

    deleteProfileMutation.onDone(() => {
      toastService.show({
        title: $translate("TextResources_DeletedProfile"),
        message: $translate("TextResources_DeletedProfileDescription"),
        severity: "success",
      });
      hasConfirmedLeave.value = true;
      router.back();
    });

    onBeforeRouteLeave(() => {
      if (!hasConfirmedLeave.value && hasChanges.value && !isNew.value) {
        return new Promise((resolve) =>
          confirmService.show(
            null,
            $translate("TextResources_SaveChangeWarningQuestion"),
            $translate("TextResources_SaveChangeWarningAnswerConfirm"),
            $translate("TextResources_SaveChangeWarningAnswerCancel"),
            () => {
              hasConfirmedLeave.value = true;
              resolve(true);
            },
            () => resolve(false)
          )
        );
      } else {
        return true;
      }
    });
    return {
      v$,
      isNew,
      profile,
      loading,
      hasChanges,
      deleteProfileMutation,
      error: useError(error),
      onPrimaryButton,
      onAddedRole,
      onRemovedRole,
      primaryButtonLoading: computed(
        () =>
          createProfileMutation.loading.value ||
          updateProfileMutation.loading.value
      ),
      deleteProfile: (event: Event) => {
        confirmService.show(
          event,
          $translate("TextResources_DeleteProfileWarningQuestion"),
          $translate("TextResources_DeleteProfileWarningnswerConfirm"),
          $translate("TextResources_DeleteProfileWarningAnswerCancel"),
          () => deleteProfileMutation.call(props.applicationId, props.id)
        );
      },
      rolePicker,
      addRemoveLoading: computed(
        () =>
          addRoleProfileMutation.loading.value ||
          removeRoleProfileMutation.loading.value
      ),
    };
  },
});

function useValidations() {
  return {
    name: { required },
  };
}
